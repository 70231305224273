// Country Flags

function formatCountry (country) {
	if (!country.id) { return country.text; }
	var $country = $(
		'<span><span class="flag flag--' + country.element.value.toLowerCase() + '"></span>' + country.text + '</span>'
	);
	return $country;
}

$(".js-select-country").select2({
	templateResult: formatCountry,
	placeholder: "Search for country"
});