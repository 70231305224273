$(document).ready(function() {
	
	// Custom dropdowns
	$('.js-select').select2({
		minimumResultsForSearch: Infinity
	});

	// Custom tooltips
	$('[data-toggle="tooltip"]').tooltip();

	// Copy to clipboard asf saf
	var clipboard = new Clipboard('#js-copy');

	clipboard.on('success', function(e) {
		//console.info('Action:', e.action);
	    //console.info('Text:', e.text);
	    //console.info('Trigger:', e.trigger);
	    $("#js-copy").attr("disabled", true);
      	$("#js-copy").text("Copied");
      	$("#js-copy").prepend("<i class='icon icon-check'></i>&nbsp;");

	    e.clearSelection();
	});

	clipboard.on('error', function(e) {
		//console.error('Action:', e.action);
		//console.error('Trigger:', e.trigger);
		$('#js-copy').html("Error copying to clipboard");
	});

	// Initialise the offcanvas menu
	( function ( $ ) {
		var controller = new slidebars();
		controller.init();
		// Toggle Slidebars
        $( '.js-toggleOffCanvas' ).on( 'click', function ( event ) {
          // Stop default action and bubbling
          event.stopPropagation();
          event.preventDefault();

          // Toggle the Slidebar with id 'id-1'
          controller.toggle( 'js-canvas' );
        } );

	} ) ( jQuery );

});