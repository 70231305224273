/* =====  JS MAIN LIBRARY AND CALLS ===== */
/*jslint browser: true*/
/*global $, jQuery, alert*/

/*
   SH: 
   TO DO: rewrite the logic of saved search to use json object.
   That way, both local storage and Firebase would use the same data object, making the switch from one to another easy
   principle: use variable json object to be interface to either local or remote storage. When action, update variable, then push the json to relevant storage
   
   ---------------------------------
   functions related to storage
   
   var userId : anonymous for local storage, proper Id for Firebase.
   
   var JSONSSL : json saved searches list acting as variable for interactions
   
   getJsonSearchList(userId) - return json object or null: depending onuserID, return local or remote json object or null

   addToJsonSearchList(label, url, socialMedia, JSONSSL): take details from current search and append to JSONSSL 
   
   removeFromJsonSearchList(searchIndex, JSONSSL): remove search from JSONSSL (if index = -1, remove all)
   
   pushJSONSSLToDataSource(searchList, dataSource) - return boolean: after JSONSSL is updated, push it to relevant dataSource (localstorage or Firebase db)
   ---------------------------------
   functions related to html display
   
   createListOfSearches(JSONSSL) - create markup for list of searches during initial load, from JSONSSL
   
   createListItem(label, url, socialMedia) - append to html list 1 new line with saved search, either coming from json during initial parsing or when saving new search
   
   removeListItem(index) - remove from html list the selected line

   removeAllItems() - remove all lines from html list
   
*/


jQuery(document).ready(function($) {

   function getNetwork(name) {
      return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
   }

   /* =====  SAVED SEARCH MANAGEMENT IN HTML 5 ===== */
   // test if browser is compatible with HTML local storage functionality
   function supports_local_storage() {
      try { 
         return 'localStorage' in window && window.localStorage !== null;
      } catch(e){
         return false;
      }
   }

   // save searches from panel to localStorage
   function saveSearch() {
      localStorage.clear(); // reinitialize the localStorage
      $(" #saved-searches-list li" ).each(function() { // go through the list of elements defined in the panel and save them in the localStorage
         localStorage.setItem($(this).attr('id') + "@" + $(this).find(".js-saved-search").text() + '@recruitem', $(this).find(".js-saved-search").attr("href"));
         //localStorage.setItem($(this).attr("class") + "@" + $(this).find("a.saved").text() + '@recruitem', $(this).find("a").attr("href"));
         //alert( $(this).find("a.saved").text() );
         //alert('<?php echo $network; ?>');
         //alert($(this).find("i").attr('id').val() + "@" + $(this).find(".js-saved-search").text() + '@recruitem', $(this).find(".js-saved-search").attr("href"));
      });
   }

   function saveSearchMarkup(url, network, label) {
      return "<li class='list__item' id='" + network + "' ><a role='button' class='list__delete-item' title='Remove'><i class='icon icon-times-circle'></i></a><a class='list__link text--truncate js-saved-search' href='"+ url +"' target='_blank'><i class='icon icon-fw icon-lg " + network + "'></i>"+ label +"</a></li>";
   }

   // get saved searches from localStorage 
   function getSavedSearch() {
      for (var key in localStorage) { 
         displayPanelLine(key);
      }
   }

   // display saved searches on the screen
   function displayPanelLine(storageKey) { 
         //alert('display line: ' + storageKey);
      if (localStorage[storageKey] !== "" && storageKey.split('@')[2]=='recruitem') {
         var explodedString = storageKey.split('@');
         $("#saved-searches-list").append(saveSearchMarkup(localStorage[storageKey], explodedString[0], explodedString[1] ));
      }
   }


   /* ===== SAVE BUTTON MANAGEMENT ===== */
   // display the save button
   function enableSaveButton() {
      $("#save-button").attr('disabled', 'false');
      $('#saved-button').text('Save Search');
   }
   
   // hide the save button and display a non clickable button "search saved"
   function disableSaveButton() {
      $("#save-button").attr("disabled", true);
      $("#save-button").text("Saved");
      $("#save-button").prepend("<i class='icon icon-check'></i>&nbsp;");
   }
   
   // when user clicks on save button
   $("#save-button").click(function() {
      disableSaveButton(); // hide save button buttons
      addDeleteAllOption();  // add the option "delete all"
      nbSavedSearches = $("#saved-searches-list li").length; // get most updated number of saved search displayed
      // add new line to panel (dont have to test if this search already exists, it is done on page load)
      var indexNewSave = nbSavedSearches + 1;
      var explodedString = $("#savedSearchLabel").val().split('@');
      //alert(explodedString);
      //alert($("#savedSearchLabel").val());
      $("#saved-searches-list").prepend(saveSearchMarkup($("#fe_text").val(), explodedString[0], explodedString[1]));
      saveSearch();  // save file with updated data
   });
   
  /* ===== SEARCH DELETION MANAGEMENT ===== */
  // display default message where the saved search appear in case no search is saved
   function addEmtpySaveMsg() {
      $('#saved-searches-list').append('<div class="panel panel--info"><h2 class="panel__title">Making the same search regularly?</h2><p>Save it, then find it here!</p></div>');
   }

  // remove default message where the saved search appear in case no search is saved
   function removeEmtpySaveMsg() {
      $('#saved-searches-list .panel').remove();
   }

  // display option "delete all" in saved search panel
   function addDeleteAllOption() {
      $('.zeta a.deleteAll').css('display', 'block');
     removeEmtpySaveMsg();
   }

   // hide option "delete all" in saved search panel
   function removeDeleteAllOption() {
      $('.zeta a.deleteAll').css('display', 'none');
     addEmtpySaveMsg();
   }
   
   // check if a search has already been saved (just by comparing result URLS)
   function searchAlreadyExists(urlToCheck) {
      var exists = false;
      $("#saved-searches-list li" ).each(function() {
         if ($(this).find("a.js-saved-search").attr("href") !== "" && urlToCheck == $(this).find("a.js-saved-search").attr("href")) {
            exists = true;
         }
      });
      return exists;
   }
   
   // when user delete one saved search. Slightly different than direct click, as button dynamically created, so must capture event differently.
   // solution is to get a selector which is static, then search for click event inside
   $('section.saved-searches').on('click', 'a.list__delete-item', function() {
      //preventDefault();
      nbSavedSearches = $("#saved-searches-list li").length; // get latest number of saved search displayed
      $(this).parent().remove();
      if ($(this).parent().find(".js-saved-search").attr("href") == $("#fe_text").val()) enableSaveButton(); // if deleted search is current search, enabling the save button
      //}
      //alert($(this).parent().find(".js-saved-search").attr("href"));
      saveSearch(); // save file with updated data
      if (nbSavedSearches == 1) { addEmtpySaveMsg();}
   });

   /* =====  MAIN (I.E. ON PAGE LOAD) ===== */ 

   // IE7 Alert close
   $('.alert .close').click(function(e) {
     e.preventDefault();
     $('.alert').fadeOut(function() {
      $(this).remove();  
     });
   });
   
   // test compatibility with html 5 for the usage of localStorage
   if (!supports_local_storage()) {
      alert('Your browser does not support HTML 5, the save search functionality is disabled.');
      $("#save-button").remove();
      $("#saved-button").remove();
      $(".saved-searches.keystone").remove();
   }
   
   getSavedSearch(); // try to access the local storage and see if there are some saved searches

   var nbSavedSearches = $("#saved-searches-list li").length; // Global variable initialization
   
   // test to display or not the option "delete all"
   if (nbSavedSearches > 0) {
      addDeleteAllOption();
   } else {
      addEmtpySaveMsg();
   }

   if (searchAlreadyExists($("#fe_text").val())) disableSaveButton(); // check if current search is already saved, disable save button if yes

   /* Show save succes on mobile
   $('#saveCookie').click(function(e) {
      e.preventDefault();
      $('#saved').delay(750).slideDown('slow').fadeOut('slow');
   });
   */

   // Enable fancy select for Country and Education
   //$('.custom-select').fancySelect();

   // OR message management with cookie
   var cookieVal = getCookie("orAnnouncement");
   if (cookieVal === undefined) {
      alertBoxShow();
   } else {
   	$(".js-alert").hide();
   }

   $(".js-dismiss").click(function() {
      setCookie("orAnnouncement","read",365);
      alertBoxClose();
   });

});

/* ==================
   COOKIE MANAGEMENT
   ==================
*/
// Create the cookie: 
function setCookie(c_name,value,exdays) { 
   var exdate=new Date();
   exdate.setDate(exdate.getDate() + exdays);
   var c_value = escape(value) + ((exdays===null) ? "" : "; expires="+exdate.toUTCString()) + ";path=/";
   document.cookie=c_name + "=" + c_value;
} 

// Check the cookie 
function getCookie(c_name) {
   var i,x,y,ARRcookies=document.cookie.split(";"); 
   for (i=0;i<ARRcookies.length;i++) {
      x=ARRcookies[i].substr(0,ARRcookies[i].indexOf("=")); 
      y=ARRcookies[i].substr(ARRcookies[i].indexOf("=")+1); 
      x=x.replace(/^\s+|\s+$/g,""); 
      if (x==c_name) return unescape(y);
   } 
} 

// just closes the alert box
function alertBoxClose() {
   $(".js-alert").slideUp();
}

// just displays the alert box
function alertBoxShow() {
   $(".js-alert").slideDown(); 
}

/*

// redirect to old interface
function alertBoxRedirect() {
   window.location.href="/switch-back/";
}

$(document).ready(function() {
   // user closes the alert box, he stays on the new interface
   $(".js-dismiss").click(function() {
      setCookie("Interface","new",365);
      alertBoxClose();
   });
   
   // user switches back to old interface
   $(".alert-box .switch-back").click(function() {
      setCookie("Interface","old",365);
      alertBoxRedirect();
   });
   
   
});

// get cookie to chose interface
var whichInterface = getCookie("Interface");

// If cookie value is set
if (whichInterface !== null) {
   // test value of cookie
   if (whichInterface == "old") {
      // user choose to switch back to old interface, we redirect him
      alertBoxRedirect();
   } // no else, as if user chose to stay on the new interface, alert box is already not displayed
} else {
// if not, displays it to give choice to switch to old interface
   alertBoxShow();
}
// else we don't do anything (alert box displayed by default)
*/